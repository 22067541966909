













































import { Component, Prop, Vue } from "vue-property-decorator";
import { StepField } from "@/types";
import { namespace } from "vuex-class";

const ItemModule = namespace("ItemModule");

@Component({
  components: { PickerDate: () => import("@/components/PickerDate.vue") }
})
export default class StepperField extends Vue {
  @Prop() readonly field!: StepField;
  @Prop() readonly autofocus!: boolean;
  @ItemModule.State
  public fields!: never;
  get isDate() {
    return this.field.type === "date";
  }
  get isInput() {
    return this.field.type === "input";
  }
  get isChoice() {
    return this.field.type === "choice";
  }
  get model(): string | number | string[] {
    return this.fields[this.field.fieldName];
  }
  set model(value: string | number | string[]) {
    this.$store.commit("ItemModule/FIELDS", {
      name: this.field.fieldName,
      value: value
    });
  }
  get getFieldRules() {
    return [
      ...(this.isInput ? [this.rules.required] : []),
      ...((this.isChoice || this.isDate) && this.field.multiple
        ? [this.rules.requiredSelectMultiple]
        : []),
      ...((this.isChoice || this.isDate) && !this.field.multiple
        ? [this.rules.requiredSelect]
        : []),
      ...(this.field.regex ? [this.rules.format(this.field.regex)] : []),
      ...(this.field.minValue ? [this.rules.min(this.field.minValue)] : []),
      ...(!!this.field.maxValue || this.field.maxValue === 0
        ? [this.rules.max(this.field.maxValue)]
        : [])
    ];
  }
  private valid = false;
  private rules = {
    required: (value: string | number) =>
      !!value || this.$t("pages.item.stepper.rules.required"),
    requiredSelect: (value: string | number) =>
      !!value || this.$t("pages.item.stepper.rules.required-select"),
    requiredSelectMultiple: (value: string | number) =>
      (Array.isArray(value) && value.length > 0) ||
      this.$t("pages.item.stepper.rules.required-select-multiple"),
    min: (min: number) => (value: number) =>
      min <= value || this.$t("pages.item.stepper.rules.min", { min: min }),
    max: (max: number) => (value: number) =>
      max >= value || this.$t("pages.item.stepper.rules.max", { max: max }),
    format: (regex: string) => (value: string) =>
      new RegExp(regex).test(value) ||
      this.$t("pages.item.stepper.rules.format")
  };
  onContinue() {
    if (this.valid) {
      try {
        (this.$refs[this.field.type] as HTMLElement).blur();
      } finally {
        this.$emit("continue");
      }
    }
  }
  created() {
    if (this.field.value) this.model = this.field.value;
  }
}
